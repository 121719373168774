<template>
	<Row class="section-gradient">
		<Container>
			<h2
				id="trainers-title"
				data-animate="true"
				class="trainers-title text-center xl:text-left text-white text-[32px] lg:text-[54px] font-medium mb-[48px]"
			>
				Meet the Trainers Behind StocksToTrade
			</h2>
			<div
				id="trainers-section"
				data-animate="true"
				class="flex flex-wrap justify-center xl:justify-between items-stretch gap-y-[32px] gap-x-[32px] xl:gap-y-0"
			>
				<trainer-card
					v-for="(trainer, index) in trainers"
					:key="index"
					:title="trainer.title"
					:image="trainer.image"
					:description="trainer.description"
					:link="trainer.link"
				/>
			</div>
		</Container>
	</Row>
</template>

<script setup>
const trainers = [
	{
		title: "Tim Bohen",
		description:
			"With over a decade of trading experience, Tim is a seasoned trader and a passionate educator, ready to help you navigate the trading world.",
		image: "/images/trainers/tim.webp",
		link: "/authors/tim-bohen/"
	},
	{
		title: "Matt Monaco",
		description:
			"A young and vibrant trader, Matt brings a fresh perspective and innovative strategies to the table.",
		image: "/images/trainers/matt.webp",
		link: "/authors/matt-monaco/"
	},
	{
		title: "Bryce Tuohey",
		description:
			"Bryce's enthusiasm for trading is contagious, and he's committed to helping new traders find their path to success.",
		image: "/images/trainers/bryce.webp",
		link: "/authors/bryce-tuohey/"
	}
];
</script>

<style lang="scss" scoped>
@media (max-width: 1215px) {
}
.section-gradient
{
  background: linear-gradient(180deg, rgba(9, 13, 24, 0) 15%, rgba(34, 41, 191, 0.5) 76.37%, rgba(9, 13, 24, 0.09) 100%);
}
</style>
