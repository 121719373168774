<template>
	<Row class="stt-on-youtube">
		<Container>
			<div class="title-container">
				<h2 class="title">StocksToTrade on YouTube</h2>
				<NuxtLink
					class="subscribe-to"
					to="https://www.youtube.com/@Stockstotrade"
					target="_blank"
				>
					Subscribe to our channel
				</NuxtLink>
			</div>

			<div class="youtube-thumbnails mb-[24px] lg:mb-[48px]">
				<div class="big-image">
					<NuxtLink
						class="youtube-item"
						to="https://www.youtube.com/watch?v=Ka638b5p3C0&"
						target="_blank"
					>
						<img
							src="/images/big-thumb-youtube-section.webp"
							class="big-thumbnail"
							width="717"
							height="504"
							alt="yt-image-1"
							loading="lazy"
						/>
						<div class="content-container big-image-content">
							<span class="content-title">
								The Best Stock Screeners for New Day Traders
							</span>
							<div class="clock-container">
								<img
									class="white-clock"
									src="/images/clock.svg"
									width="24px"
									height="24px"
									alt="clock-image"
									loading="lazy"
								/>
								<span class="clock-time">11 min</span>
							</div>
						</div>
					</NuxtLink>
				</div>
				<div class="small-image">
					<div class="small-image-container">
						<NuxtLink
							class="youtube-item"
							to="https://www.youtube.com/watch?v=y3pJarOEe_k"
							target="_blank"
						>
							<div class="image-container">
								<img
									src="/images/small-thumb-1-risk.webp"
									width="197"
									height="152"
									class="small-thumbnail"
									alt="yt-image-2"
									loading="lazy"
								/>
							</div>
							<div class="content-container">
								<span class="content-title">
									Mastering Risk Management: The Key to Sustainable Trading Success
								</span>
								<div class="clock-container">
									<img
										src="/images/clock.svg"
										class="grey-clock"
										width="24px"
										height="24px"
										alt="clock-image"
										loading="lazy"
									/>
									<span class="clock-time">13 min</span>
								</div>
							</div>
						</NuxtLink>
					</div>
					<div class="small-image-container">
						<NuxtLink
							class="youtube-item"
							to="https://www.youtube.com/watch?v=bGUuv7ogVFQ"
							target="_blank"
						>
							<div class="image-container">
								<img
									src="/images/small-thumb-1-predict.webp"
									width="197"
									height="152"
									alt="yt-image-2"
									loading="lazy"
								/>
							</div>
							<div class="content-container">
								<span class="content-title">
									The #1 Tool To Use To Predict Stocks to Buy
								</span>
								<div class="clock-container">
									<img
										src="/images/clock.svg"
										class="grey-clock"
										width="24px"
										height="24px"
										alt="clock-image"
										loading="lazy"
									/>
									<span class="clock-time">11 min</span>
								</div>
							</div>
						</NuxtLink>
					</div>
					<div class="small-image-container">
						<NuxtLink
							class="youtube-item"
							to="https://www.youtube.com/watch?v=F7jJJBI6pR8"
							target="_blank"
						>
							<div class="image-container">
								<img
									src="/images/small-thumb-1-best.webp"
									width="197"
									height="152"
									alt="yt-image-2"
									loading="lazy"
								/>
							</div>
							<div class="content-container">
								<span class="content-title">
									Day Trading 101, Episode 1: Day Trading for Beginners
								</span>
								<div class="clock-container">
									<img
										src="/images/clock.svg"
										class="grey-clock"
										width="24px"
										height="24px"
										alt="clock-image"
										loading="lazy"
									/>
									<span class="clock-time">10 min</span>
								</div>
							</div>
						</NuxtLink>
					</div>
				</div>
			</div>
			<NuxtLink
				class="subscribe-to-mobile"
				to="https://www.youtube.com/@Stockstotrade"
				target="_blank"
			>
				Subscribe to our channel
			</NuxtLink>
		</Container>
	</Row>
</template>

<style lang="scss" scoped>
@import "../assets/utils.scss";

.stt-on-youtube {
	// background-color: $black;
	background: var(
		--gradient,
		linear-gradient(
			73deg,
			rgba(0, 87, 255, 0.2) 4.01%,
			rgba(222, 89, 255, 0.2) 81.74%
		)
	);

	.subscribe-to {
		display: none;
	}

	@include breakpoint(lg) {
		.subscribe-to {
			display: inline-flex;
		}
		.subscribe-to-mobile {
			display: none;
		}
	}
}

.title-container {
	padding-bottom: 32px;
	@include breakpoint(lg) {
		padding-bottom: 72px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.title {
	color: #fff;
	font-feature-settings:
		"clig" off,
		"liga" off;
	font-family: Open Sauce Sans;
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 67.2px */
	letter-spacing: -2px;
	text-align: center;

	@include breakpoint(lg) {
		font-size: 56px;
		text-align: start;
	}
}

.subscribe-to,
.subscribe-to-mobile {
	color: #4383ff;
	font-size: 18px;
	display: inline-flex;
	font-family: DM Sans;
	font-style: normal;
	font-weight: 700;
	line-height: 26px;

	&::after {
		content: "";
		display: inline-block;
		width: 28px;
		height: 28px;
		background-color: #4a8fff;
		background-image: url("/images/arrow-right.svg");
		background-repeat: no-repeat;
		background-size: 18px;
		background-position: center;
		border-radius: 50%;
		margin-left: 8px;
		vertical-align: middle;
	}

	&:hover {
		color: white;
	}
}

.subscribe-to-mobile {
	display: flex;
	justify-content: center;
}

.youtube-thumbnails {
	display: grid;
	gap: 24px;
	line-height: 0;

	@include breakpoint(lg) {
		grid-template-columns: 3fr 2fr;
	}

	img {
		border-radius: 16px;
	}
}

.big-image {
	flex-shrink: 0;
	overflow: hidden;
	max-height: 504px;
	.youtube-item {
		text-decoration: none;
		display: block;
		position: relative;

		@include breakpoint(lg) {
			.big-image-content {
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 2;
				width: 100%;
				text-align: start;
				max-height: 504px;

				.content-title {
					font-size: 20px;
				}

				.clock-time {
					font-family: DM Sans;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 22px;
				}
			}
		}
		&::before {
			display: none;

			@include breakpoint(lg) {
				display: block;
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(36, 36, 36, 0.6);
				border-radius: 16px;
				z-index: 1;
			}
		}

		&::after {
			content: "";
			position: absolute;
			top: 35%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 96px;
			height: 96px;
			background: url("/images/play-button.svg") no-repeat center center;
			background-size: contain;
			z-index: 2;
			transition: transform 0.3s ease;

			@include breakpoint(lg) {
				top: 50%;
				width: 72px;
				height: 72px;
			}
		}

		&:hover::after {
			transform: translate(-50%, -50%) scale(1.5);
		}
	}

	.big-thumbnail {
		width: 100%;
		height: auto;
		top: -0.21px;
		object-fit: cover;
		display: block;
		max-height: 504px;

		@include breakpoint(lg) {
			width: 100%;
			height: 504px;
		}
	}
}

.youtube-item:first-child {
	grid-column: span 2;
	grid-row: span 3;
}

.small-image-container {
	align-items: center;

	.youtube-item {
		display: flow;
		.image-container {
			position: relative;

			&::before {
				display: none;

				@include breakpoint(lg) {
					display: block;
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(36, 36, 36, 0.6);
					border-radius: 16px;
					z-index: 1;
				}
			}

			&::after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 96px;
				height: 96px;
				background: url("/images/play-button.svg") no-repeat center center;
				background-size: contain;
				z-index: 2;
				transition: transform 0.3s ease;

				@include breakpoint(lg) {
					width: 72px;
					height: 72px;
				}
			}
			&:hover::after {
				transform: translate(-50%, -50%) scale(1.5);
			}

			img {
				width: 100%;
				height: auto;
				object-fit: cover;
				display: block;

				@include breakpoint(lg) {
					width: 197px;
					height: 150px;
				}
			}
		}

		@include breakpoint(lg) {
			display: flex;
		}
	}
}

.content-container {
	display: flex;
	padding-top: 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	flex: 1 0 0;

	@include breakpoint(lg) {
		padding: 24px;
		padding-right: 0;
	}
}

.content-title {
	color: #fff;
	font-family: DM Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	align-self: stretch;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.25;
	-webkit-line-clamp: 2;
	&:hover {
		color: #4383ff;
	}

	@include breakpoint(lg) {
		font-size: 18px;
	}
}

.clock-container {
	display: flex;
	align-items: center;

	img {
		margin-right: 8px;
		height: 32px;
		width: 32px;

		@include breakpoint(lg) {
			height: 24px;
			width: 24px;
		}
	}

	.clock-time {
		color: #a4acb9;
		/* Body/Small/Regular */
		font-family: DM Sans;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px; /* 157.143% */

		@include breakpoint(lg) {
			font-size: 14px;
		}
	}
}

.small-image {
	display: flex;
	flex-direction: column;
	gap: 24px;
}
</style>
