<template>
	<div class="card">
		<slot />
	</div>
</template>

<style lang="scss" scoped>
.card {
	background: $slate-800;
	padding: 32px;
	background: linear-gradient(
		180deg,
		rgba($black, 1) 30%,
		rgba($bluish-black, 1) 100%
	);
}
</style>
