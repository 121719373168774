<template>
	<div
		class="lg:px-4"
		id="popular-post-section"
	>
		<div
			class="flex flex-col sm:flex-row justify-center sm:justify-between items-center mb-[32px] lg:my-[64px] text-center sm:text-left"
		>
			<h2 class="text-[32px] lg:text-6xl font-medium text-white leading-10">
				Popular Posts
			</h2>
			<LinkWithArrow
				text-color="text-white"
				label="All Blogs & Insights"
				class="hidden sm:flex"
				link="/blog"
			/>
		</div>
		<div
			class="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[24px]"
		>
			<popular-post-card
				v-if="posts && posts.length > 0"
				v-for="(post, index) in posts"
				:key="index"
				:title="post.title"
				:image="post.image"
				:description="post.description"
				:comments_count="post.comments_count"
				:link="post.link"
				style="flex: 1; flex-grow: 1"
			/>
		</div>

		<div class="sm:hidden flex justify-center items-center mt-4">
			<LinkWithArrow
				text-color="text-white"
				label="All Blogs & Insights"
			/>
		</div>
	</div>
</template>

<script setup>
const { data: posts } = await useAsyncData(
	"popular-posts",
	async ({ $config }) => {
		try {
			let posts = [];

			const responseGraphQl = await Promise.all(
				[8592, 9178, 8877].map(async (id) => {
					const responseGraphQl = await $fetch(
						$config.public["hostname"] + "graphql",
						{
							method: "post",
							body: {
								query: `
					query GetPostById {
						post(id: ${id}, idType: DATABASE_ID) {
							title
							uri
							commentCount
							excerpt
							featuredImage {
								node {
									sourceUrl
								}
							}
						}
					}`
							}
						}
					);

					return responseGraphQl.data.post;
				})
			);

			const responsePageGraphQl = await $fetch(
				$config.public["hostname"] + "graphql",
				{
					method: "post",
					body: {
						query: `
					query GetPageById {
						pageBy(uri : "/stock-screener-for-day-trading/") {
							title
							uri
							commentCount
							content
							featuredImage {
								node {
									sourceUrl
								}
							}
						}
					}`
					}
				}
			);

			if (responseGraphQl) {
				posts = responseGraphQl.map((post) => {
					return {
						title: post.title,
						image: post.featuredImage.node.sourceUrl,
						link: post.uri,
						description: post.excerpt,
						comments_count: post.commentCount
					};
				});
			}

			if (responsePageGraphQl) {
				posts.push({
					title: responsePageGraphQl.data.pageBy.title,
					image: responsePageGraphQl.data.pageBy.featuredImage.node.sourceUrl,
					link: responsePageGraphQl.data.pageBy.uri,
					description: responsePageGraphQl.data.pageBy.content,
					comments_count: 0
				});
			}

			return posts;
		} catch (error) {
			console.error(error);
		}
	}
).catch((error) => {
	if (error.cause.statusCode === 404) {
		throw createError({
			statusCode: 404,
			fatal: true
		});
	} else {
		throw createError({
			statusCode: 500,
			statusMessage: error,
			fatal: true
		});
	}
});
</script>
