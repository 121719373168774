<template>
	<NuxtLink
		:href="link"
		class="max-w-md mx-auto rounded-[16px] overflow-hidden"
	>
		<div class="md:h-[206px] flex justify-center items-center">
			<img
				class="w-389 md:h-320 object-contain rounded-[16px] xl:rounded-[24px]"
				:src="image"
				alt="Popular Post Image"
				loading="lazy"
			/>
		</div>
		<div class="py-6">
			<h3 class="text-xl font-bold text-white mb-4">
				{{ truncateText(title, 48) }}
			</h3>
			<p class="text-base font-normal text-slate-100 mb-4">
				{{ truncateText(parsedDescription, 132) }}
			</p>
			<div class="flex gap-5">
				<!-- <div class="flex items-end gap-1">
					<svg
						class="text-slate-300 h-6 w-auto"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g id="heart">
							<path
								id="Icon"
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M11.9932 5.13581C9.9938 2.7984 6.65975 2.16964 4.15469 4.31001C1.64964 6.45038 1.29697 10.029 3.2642 12.5604C4.89982 14.6651 9.84977 19.1041 11.4721 20.5408C11.6536 20.7016 11.7444 20.7819 11.8502 20.8135C11.9426 20.8411 12.0437 20.8411 12.1361 20.8135C12.2419 20.7819 12.3327 20.7016 12.5142 20.5408C14.1365 19.1041 19.0865 14.6651 20.7221 12.5604C22.6893 10.029 22.3797 6.42787 19.8316 4.31001C17.2835 2.19216 13.9925 2.7984 11.9932 5.13581Z"
								stroke="#A4ACB9"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</g>
					</svg>

					<span class="text-sm font-normal text-slate-300">{{ likes_count }}</span>
				</div> -->
				<div class="flex items-end gap-1">
					<svg
						class="text-slate-300 h-6 w-auto"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g id="message-circle-02">
							<path
								id="Icon"
								d="M21 12C21 16.9706 16.9706 21 12 21C10.8029 21 9.6603 20.7663 8.61549 20.3419C8.41552 20.2607 8.31554 20.2201 8.23472 20.202C8.15566 20.1843 8.09715 20.1778 8.01613 20.1778C7.9333 20.1778 7.84309 20.1928 7.66265 20.2229L4.10476 20.8159C3.73218 20.878 3.54589 20.909 3.41118 20.8512C3.29328 20.8007 3.19933 20.7067 3.14876 20.5888C3.09098 20.4541 3.12203 20.2678 3.18413 19.8952L3.77711 16.3374C3.80718 16.1569 3.82222 16.0667 3.82221 15.9839C3.8222 15.9028 3.81572 15.8443 3.798 15.7653C3.77988 15.6845 3.73927 15.5845 3.65806 15.3845C3.23374 14.3397 3 13.1971 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
								stroke="#A4ACB9"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</g>
					</svg>

					<span class="text-sm font-normal text-slate-300">
						{{ comments_count }}
					</span>
				</div>
			</div>
		</div>
	</NuxtLink>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
	title: String,
	description: String,
	image: String,
	likes_count: Number,
	comments_count: Number,
	link: String
});

const parsedDescription = computed(() => {
	if (props.description && props.description.includes(`<p>`)) {
		return props.description.split(`<p>`)[1].split(`</p>`)[0];
	} else {
		return props.description;
	}
});
</script>
