<template>
	<section class="hero-section">
		<Row class="relative z-[2]">
			<Container
				class="flex min-h-[572px] flex-wrap lg:flex-row flex-col items-center"
			>
				<div
					class="relative z-[3] flex w-full max-w-[660px] lg:max-w-[600px] xl:max-w-[720px] flex-col gap-[32px] mx-auto lg:mx-0"
				>
					<h1
						id="hero-title"
						class="gradient-title-white text-center lg:text-left"
					>
						Elevate Your Trading Game with StocksToTrade
					</h1>

					<div
						id="hero-lead-capture-form"
						class="flex flex-col gap-[32px] items-center lg:items-start"
					>
						<h3
							id="hero-subtitle"
							class="text-lg text-white max-w-[600px] text-center lg:text-left"
						>
							Unlock the secrets to successful trading with our exclusive webinars,
							powerful AI and algorithm-enhanced tools
						</h3>
						<div class="flex max-lg:flex-wrap items-center gap-[16px]">
							<LeadCaptureForm
								id="sttwl-mainsite-top"
								cta-appearence="primary"
								class="max-w-[600px]"
							/>

							<!-- <div class="flex gap-[18px] mx-auto">
								<NuxtLink
									id="whatsapp-logo"
									href="https://whatsapp.com/channel/0029VaaVr55EQIatcKRurM18"
									target="_blank"
									class="has-tooltip"
								>
									<img
										src="/images/WhatsApp_icon.png"
										class="h-[52px] object-cover min-w-[52px]"
									/>
									<div class="tooltip">
										<small class="text-base text-slate-500 whitespace-nowrap">
											Join our chanel!
										</small>
									</div>
								</NuxtLink>
								<NuxtLink
									id="telegram-logo"
									href="https://t.me/+oLn4_X4cWWJiZjgx"
									target="_blank"
									class="has-tooltip"
								>
									<img
										src="/images/Telegram_logo.png"
										class="h-[48px] object-cover min-w-[48px] max-w-[48px]"
									/>

									<div class="tooltip">
										<small class="text-base text-slate-500 whitespace-nowrap">
											Join our chanel!
										</small>
									</div>
								</NuxtLink>
							</div> -->
						</div>

						<JoinPitch
							id="hero-join-pitch"
							:is-white="true"
						/>
					</div>
				</div>
				<div class="hero-image-container my-[64px] lg:my-0">
					<div class="swoosh-mobile fade-in lg:hidden">
						<svg
							class="swoosh"
							width="593"
							height="243"
							viewBox="0 0 593 243"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M591.022 225.246C589.57 229.371 586.145 232.739 580.861 235.35C575.575 237.962 568.465 239.796 559.723 240.865C542.241 243.002 518.331 242.065 489.677 238.267C432.377 230.67 356.199 211.639 274.733 182.969C193.267 154.3 121.96 121.428 72.531 91.4648C47.8133 76.4812 28.5868 62.237 16.2959 49.6223C10.1498 43.3143 5.75573 37.4317 3.27052 32.085C0.786235 26.7402 0.225587 21.9692 1.67736 17.8438C3.12914 13.7185 6.55424 10.3501 11.8383 7.7392C17.1243 5.12733 24.2341 3.29311 32.9761 2.22455C50.4584 0.0876469 74.3681 1.02391 103.022 4.82278C160.322 12.4195 236.5 31.4505 317.966 60.1201C399.432 88.7897 470.739 121.661 520.168 151.625C544.886 166.608 564.112 180.852 576.403 193.467C582.549 199.775 586.943 205.658 589.428 211.004C591.913 216.349 592.473 221.12 591.022 225.246Z"
								stroke="url(#paint0_linear_2270_820)"
								stroke-width="0.879963"
							/>
							<defs>
								<linearGradient
									id="paint0_linear_2270_820"
									x1="296.286"
									y1="197.408"
									x2="358.324"
									y2="71.1333"
									gradientUnits="userSpaceOnUse"
								>
									<stop stop-color="#5988FF" />
									<stop
										offset="1"
										stop-opacity="0"
									/>
								</linearGradient>
							</defs>
						</svg>

						<svg
							class="hero-shine"
							width="69"
							height="64"
							viewBox="0 0 69 64"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M44.9311 54.9613C41.4939 55.2663 39.5191 55.5468 38.3067 56.4739C36.8818 57.5596 36.5444 59.548 36.1694 63.5247C35.7819 59.4016 35.432 57.4254 33.8696 56.3641C32.6572 55.5346 30.6949 55.2663 27.4077 54.9735C30.8324 54.6686 32.8197 54.388 34.0196 53.4731C35.457 52.3752 35.7944 50.3991 36.1694 46.4102C36.5194 50.0819 36.8319 52.0459 37.9942 53.1803C39.1566 54.3148 41.1815 54.632 44.9311 54.9613Z"
								fill="#95B0F4"
							/>
							<path
								d="M33.2489 16.7334C26.8328 17.3027 23.1465 17.8264 20.8834 19.557C18.2236 21.5835 17.5937 25.2951 16.8937 32.7183C16.1705 25.0219 15.5172 21.3331 12.6008 19.352C10.3377 17.8036 6.67468 17.3027 0.538574 16.7562C6.93132 16.1869 10.641 15.6632 12.8808 13.9554C15.5639 11.906 16.1938 8.21721 16.8937 0.77124C17.547 7.62518 18.1303 11.2912 20.3001 13.4089C22.4699 15.5266 26.2496 16.1186 33.2489 16.7334Z"
								fill="#95B0F4"
							/>
							<path
								d="M68.2959 27.5765C63.9421 27.9627 61.4407 28.3181 59.905 29.4924C58.1002 30.8676 57.6727 33.3862 57.1978 38.4234C56.707 33.2008 56.2637 30.6977 54.2847 29.3534C52.749 28.3027 50.2634 27.9627 46.0996 27.5919C50.4375 27.2056 52.9548 26.8502 54.4747 25.6914C56.2953 24.3008 56.7228 21.7976 57.1978 16.745C57.6411 21.3959 58.0368 23.8836 59.5092 25.3206C60.9816 26.7575 63.5463 27.1593 68.2959 27.5765Z"
								fill="#95B0F4"
							/>
						</svg>
					</div>
					<GradientBorderBlock
						parent-class="hero-image-2 !rounded-[14px] "
						class="!rounded-[12px] iframe-container"
					>
						<LazyTopGainerIframe
							v-if="showIframe"
							:primary="true"
						/>
						<img
							src="/images/loading-mock.webp"
							v-else
						/>
					</GradientBorderBlock>
					<div
						id="shine"
						class="hero-image-1 !rounded-[14px]"
					>
						<GradientBorderBlock
							parent-class="!rounded-[14px]"
							class="!rounded-[12px]"
						>
							<img
								src="/images/hero-screenshot-1.webp"
								alt="hero-screenshot-1.webp"
								loading="eager"
								srcset="
									/images/hero-screenshot-1_50.webp 700w,
									/images/hero-screenshot-1.webp    900w
								"
								sizes="(max-width: 700px) 700px, 900px"
								width="582"
								height="520"
							/>
						</GradientBorderBlock>
						<svg
							class="swoosh hidden lg:block"
							width="593"
							height="243"
							viewBox="0 0 593 243"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M591.022 225.246C589.57 229.371 586.145 232.739 580.861 235.35C575.575 237.962 568.465 239.796 559.723 240.865C542.241 243.002 518.331 242.065 489.677 238.267C432.377 230.67 356.199 211.639 274.733 182.969C193.267 154.3 121.96 121.428 72.531 91.4648C47.8133 76.4812 28.5868 62.237 16.2959 49.6223C10.1498 43.3143 5.75573 37.4317 3.27052 32.085C0.786235 26.7402 0.225587 21.9692 1.67736 17.8438C3.12914 13.7185 6.55424 10.3501 11.8383 7.7392C17.1243 5.12733 24.2341 3.29311 32.9761 2.22455C50.4584 0.0876469 74.3681 1.02391 103.022 4.82278C160.322 12.4195 236.5 31.4505 317.966 60.1201C399.432 88.7897 470.739 121.661 520.168 151.625C544.886 166.608 564.112 180.852 576.403 193.467C582.549 199.775 586.943 205.658 589.428 211.004C591.913 216.349 592.473 221.12 591.022 225.246Z"
								stroke="url(#paint0_linear_2270_820)"
								stroke-width="0.879963"
							/>
							<defs>
								<linearGradient
									id="paint0_linear_2270_820"
									x1="296.286"
									y1="197.408"
									x2="358.324"
									y2="71.1333"
									gradientUnits="userSpaceOnUse"
								>
									<stop stop-color="#5988FF" />
									<stop
										offset="1"
										stop-opacity="0"
									/>
								</linearGradient>
							</defs>
						</svg>

						<svg
							class="hero-shine hidden lg:block"
							width="69"
							height="64"
							viewBox="0 0 69 64"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M44.9311 54.9613C41.4939 55.2663 39.5191 55.5468 38.3067 56.4739C36.8818 57.5596 36.5444 59.548 36.1694 63.5247C35.7819 59.4016 35.432 57.4254 33.8696 56.3641C32.6572 55.5346 30.6949 55.2663 27.4077 54.9735C30.8324 54.6686 32.8197 54.388 34.0196 53.4731C35.457 52.3752 35.7944 50.3991 36.1694 46.4102C36.5194 50.0819 36.8319 52.0459 37.9942 53.1803C39.1566 54.3148 41.1815 54.632 44.9311 54.9613Z"
								fill="#95B0F4"
							/>
							<path
								d="M33.2489 16.7334C26.8328 17.3027 23.1465 17.8264 20.8834 19.557C18.2236 21.5835 17.5937 25.2951 16.8937 32.7183C16.1705 25.0219 15.5172 21.3331 12.6008 19.352C10.3377 17.8036 6.67468 17.3027 0.538574 16.7562C6.93132 16.1869 10.641 15.6632 12.8808 13.9554C15.5639 11.906 16.1938 8.21721 16.8937 0.77124C17.547 7.62518 18.1303 11.2912 20.3001 13.4089C22.4699 15.5266 26.2496 16.1186 33.2489 16.7334Z"
								fill="#95B0F4"
							/>
							<path
								d="M68.2959 27.5765C63.9421 27.9627 61.4407 28.3181 59.905 29.4924C58.1002 30.8676 57.6727 33.3862 57.1978 38.4234C56.707 33.2008 56.2637 30.6977 54.2847 29.3534C52.749 28.3027 50.2634 27.9627 46.0996 27.5919C50.4375 27.2056 52.9548 26.8502 54.4747 25.6914C56.2953 24.3008 56.7228 21.7976 57.1978 16.745C57.6411 21.3959 58.0368 23.8836 59.5092 25.3206C60.9816 26.7575 63.5463 27.1593 68.2959 27.5765Z"
								fill="#95B0F4"
							/>
						</svg>
					</div>
				</div>
			</Container>
		</Row>
	</section>
</template>

<script setup>
import anime from "animejs";

const showIframe = ref(false);
const breakpoints = reactive(
	useBreakpoints({
		small: 768,
		xxxl: 2200
	})
);

onMounted(async () => {
	if (process.client) {
		if (breakpoints.isGreaterOrEqual("small")) {
			const textWrapper = document.querySelector(".gradient-title-white");
			const scale_value = breakpoints.isGreater("small") ? [1, 1] : [0.75, 0.75];
			textWrapper.innerHTML = textWrapper.textContent.replace(
				/\S/g,
				"<span class='letter'>$&</span>"
			);
			anime
				.timeline()
				.add({
					targets: "#hero-title",
					opacity: [0, 1],
					easing: "easeInOutQuad",
					duration: 10
				})
				.add({
					targets: "#hero-title .letter",
					opacity: [0, 1],
					easing: "easeInOutQuad",
					duration: 500,
					delay: (el, i) => 8 * (i + 1)
				})
				.add({
					targets: "#hero-lead-capture-form",
					opacity: [0, 1],
					easing: "easeInOutQuad",
					translateY: [25, 0],
					duration: 400
				});

			anime({
				targets: "#shine",
				easing: "easeInOutQuad",
				opacity: [0, 1],
				translateX: [breakpoints.isGreaterOrEqual("xxxl") ? 300 : 800, 0],
				duration: 400
			});

			anime({
				targets: ".hero-image-2",
				easing: "easeInOutQuad",
				opacity: [0, 1],
				translateX: [breakpoints.isGreaterOrEqual("xxxl") ? 300 : 800, 0],
				scale: scale_value,
				duration: 400
			});
		}

		await setTimeout(() => {
			showIframe.value = true;
		}, 1500);
	}
});
</script>
<style lang="scss" scoped>
#hero-lead-capture-form {
	@include breakpoint(md) {
		opacity: 0;
		transform: translateY(25);
	}
}
.hero-image-container {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	// height: 636px;
	// width: 700px;
	max-width: 585px;

	@include breakpoint(lg) {
		max-width: unset;
		position: unset;
	}
	@include breakpoint(xxxl) {
		position: relative;
	}
}

@media (min-width: 1024px) and (max-width: 1280px) {
	.hero-image-container {
		// transform: scale(0.7);
	}
}
.hero-shine {
	position: absolute;
	top: 16px;
	z-index: 1;
	height: 36px;
	@include breakpoint(lg) {
		height: 64px;
		right: 564px;
		top: -364px;
		bottom: 0;
		margin: auto;
	}
}
.swoosh {
	position: absolute;
	top: 76px;
	left: 16px;
	margin: auto;
	z-index: 1;
	width: calc(100% - 36px);
	@include breakpoint(lg) {
		top: 0;
		bottom: 0;
		right: 24px;
		left: unset;
		width: auto;
	}
}

.hero-image-1 {
	top: 0;
	margin-left: auto;
	max-width: calc(100% - 52px);
	outline: 6px solid rgba($white, 0.05);
	border-radius: 12px !important;
	@include breakpoint(md) {
		max-width: 585px;
	}
	@include breakpoint(lg) {
		position: absolute;
		max-height: 524px;
		bottom: 0;
		top: 0;
		margin: auto;
		right: -240px;
		opacity: 0;
		transform: translateX(800);
	}
	@include breakpoint(xl) {
		right: -64px;
	}

	@include breakpoint(xxxl) {
		height: 524px;
		width: 582px;
		right: -440px;
	}
}
.hero-image-2 {
	position: absolute;
	left: 0;
	outline: 6px solid rgba($white, 0.05);
	z-index: 2;
	bottom: -76px;
	max-width: 264px;

	@include breakpoint(lg) {
		left: auto;
		position: absolute;
		right: 124px;
		bottom: 24px;
		opacity: 0;
		transform: translateX(800);
	}
	@include breakpoint(xl) {
		right: 380px;
	}
	@include breakpoint(xxxl) {
		right: -40px;
		width: 264px;
		height: 282px;
		top: 0;
	}
}

h1#hero-title {
	@include breakpoint(md) {
		opacity: 0;
	}
}
h1#hero-title .letter {
	@include breakpoint(md) {
		opacity: 0;
	}
}

.fade-in {
	opacity: 0; /* Initially invisible */
	animation: fadeInAnimation 500ms ease-in forwards 2s; /* Animation properties with a delay of 500ms */
}

@keyframes fadeInAnimation {
	from {
		opacity: 0; /* Starting opacity */
	}
	to {
		opacity: 1; /* Ending opacity */
	}
}

.swoosh-mobile {
	position: relative;
	z-index: 3;

	.swoosh {
		top: 0;
	}
}
</style>
