<template>
	<Row id="glide-slider">
		<Container>
			<div
				id="bohen-slider"
				class="lg:grid grid-cols-3 items-center gap-[32px] glide"
			>
				<div class="col-span-1 slider-card mb-[32px] lg:mb-0">
					<img
						data-animate="true"
						id="bohen-shot"
						src="/images/bohen.webp"
						alt="Tim Bohen profile pic"
						class="slider-card-image hidden lg:block"
						loading="lazy"
						width="394"
						height="512"
					/>

					<div
						id="slider-block"
						class="flex flex-col gap-[24px]"
						data-animate="true"
					>
						<h3
							class="text-[32px] lg:text-[56px] text-center lg:text-left leading-tight font-medium lg:pr-[64px] relative z-[1]"
						>
							Don’t Just Take Our Word for It
						</h3>
						<p class="text-lg text-center lg:text-left text-slate-100 relative z-[1]">
							Hear from Our Satisfied Users
						</p>

						<div
							class="flex gap-[32px] relative z-[1]"
							data-glide-el="controls"
							v-if="breakpoints.isGreater('small')"
						>
							<button
								class="slider-button"
								data-glide-dir="<"
							>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M20 24L12 16L20 8"
										stroke="white"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</button>
							<button
								class="slider-button"
								data-glide-dir=">"
							>
								<svg
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12 24L20 16L12 8"
										stroke="white"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
				<div
					class="col-span-2 glide--ltr glide--slider glide--swipeable relative"
					id="slider"
					data-animate="true"
				>
					<div class="slider-container">
						<div
							class="glide__track"
							data-glide-el="track"
						>
							<ul
								class="glide__slides"
								ref="slider"
							>
								<li
									class="glide__slide"
									v-for="testimonial in testimonials"
								>
									<GradientBorderBlock parent-class=" secondary bg-slate-800">
										<Card class="lg:h-[512px] flex flex-col gap-[24px] lg:gap-[32px]">
											<div class="flex gap-[16px] items-center">
												<svg
													width="40"
													height="40"
													viewBox="0 0 40 40"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<g clip-path="url(#clip0_2270_2151)">
														<path
															d="M13.7384 12.2332L3.1051 13.7749L2.91676 13.8132C2.63166 13.8889 2.37176 14.0389 2.16359 14.2479C1.95541 14.4569 1.80644 14.7174 1.73187 15.0027C1.6573 15.2881 1.65981 15.5882 1.73914 15.8723C1.81848 16.1564 1.97179 16.4144 2.18343 16.6199L9.88677 24.1182L8.0701 34.7099L8.04843 34.8932C8.03098 35.1881 8.09221 35.4823 8.22585 35.7457C8.35949 36.0091 8.56074 36.2323 8.80899 36.3924C9.05724 36.5525 9.34358 36.6437 9.63868 36.6567C9.93378 36.6697 10.227 36.6041 10.4884 36.4665L19.9984 31.4665L29.4868 36.4665L29.6534 36.5432C29.9285 36.6516 30.2275 36.6848 30.5197 36.6395C30.8119 36.5942 31.0867 36.4719 31.3161 36.2853C31.5454 36.0987 31.721 35.8545 31.8248 35.5776C31.9286 35.3007 31.9569 35.0013 31.9068 34.7099L30.0884 24.1182L37.7951 16.6182L37.9251 16.4765C38.1108 16.2478 38.2326 15.974 38.278 15.6829C38.3234 15.3918 38.2908 15.0938 38.1836 14.8194C38.0764 14.545 37.8983 14.3039 37.6676 14.1207C37.4368 13.9375 37.1617 13.8188 36.8701 13.7765L26.2368 12.2332L21.4834 2.59987C21.3459 2.32076 21.133 2.08573 20.8687 1.92138C20.6045 1.75703 20.2996 1.66992 19.9884 1.66992C19.6773 1.66992 19.3723 1.75703 19.1081 1.92138C18.8439 2.08573 18.631 2.32076 18.4934 2.59987L13.7384 12.2332Z"
															fill="#FFCB13"
														/>
													</g>
													<defs>
														<clipPath id="clip0_2270_2151">
															<rect
																width="40"
																height="40"
																fill="white"
															/>
														</clipPath>
													</defs>
												</svg>

												<span class="text-[28px]"><b>5.0</b></span>
											</div>
											<NuxtLink
												:href="testimonial.url"
												target="_blank"
											>
												<p class="text mt-auto">
													{{ truncateText(testimonial.review, 160) }}
												</p>
											</NuxtLink>

											<div class="flex gap-[24px] mt-auto">
												<img
													v-if="testimonial.img"
													:src="testimonial.img"
													:alt="testimonial.img"
													class="rounded-[40px] h-[36px] w-[36px]"
													loading="lazy"
													height="36"
													width="36"
												/>
												<span
													class="testimonail-abr"
													v-else
												>
													{{ testimonial.author.substring(0, 2) }}
												</span>
												<div>
													<h5 class="text-lg">
														<b>{{ testimonial.author }}</b>
													</h5>
													<p class="text-base text-slate-200">StocksToTrade Subscriber</p>
												</div>
											</div>
										</Card>
									</GradientBorderBlock>
								</li>

								<li
									class="glide__slide"
									v-if="breakpoints.isGreater('small')"
								>
									<span class="min-w-[120px] w-full block" />
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div
					class="mt-[32px] flex gap-[32px] justify-center relative z-[1]"
					data-glide-el="controls"
					v-if="!breakpoints.isGreater('small')"
				>
					<button
						class="slider-button"
						data-glide-dir="<"
					>
						<svg
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M20 24L12 16L20 8"
								stroke="white"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</button>
					<button
						class="slider-button"
						data-glide-dir=">"
					>
						<svg
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12 24L20 16L12 8"
								stroke="white"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</button>
				</div>
			</div>
		</Container>
	</Row>
</template>

<script setup>
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";

const testimonials = [
	{
		img: "https://user-images.trustpilot.com/652542e93415470012717ef6/73x73.png",
		author: "RichWorkman",
		review: `Easy to learn and Oracle is the best to find low float stocks. I am able to scan tickers in an instant. Your Program is helping me and my family to become successful traders. 2024 we hope to start to see radical change in our finances. Thanks to all of you, for your hard work and the quality leadership you share and show.

Rob Booker is the greatest teacher ever. He breaks all the mumbo jumbo down (step by step.) Tim Bohen creates the morning atmosphere with great enthusiasm and helps make the trading morning exciting. I am so glad I am able to be a part of the greatest History makers in our day. I wont use stock lingo as I am still learning but I have not nor will I stop until I have conquered ($500 Daily Income)

You Need me to be verified - contact me i have the g mail ins workman account.`,
		url: "https://www.trustpilot.com/reviews/659018936c819dc16a9511e5"
	},
	{
		img: "https://secure.gravatar.com/avatar/e542236f92b493f9e48584763af17863?s=96&d=mm&r=g",
		author: "Michael Craig",
		review: `In my quest to learn penny stock trading since early November, I've found STT to be an extremely valuable tool. I still have more features to unlock, but with hard work, study, trade discipline, checking alerts, and by showing to Pre-market Prep and other trade webinars by Tim B, Matt, Caleb, Rob and others, I finally got enough confidence to realize my first gain of over $132 in one hour today! This is a solid milestone towards my goal of $100K in my first year.`,
		url: "https://www.trustpilot.com/reviews/657a14ccae42afad06f96149"
	},
	{
		img: "https://user-images.trustpilot.com/64f74d09a2ea8e00134bd4ef/73x73.png",
		author: "Boniface Mwamba",
		review: `Having a full-time job and trading is a challenge. The Daily Income Trader System and STT have really helped me focus on my trade plan instead of spending time searching for stocks to trade, finding key levels, et cetera. One other thing I like about the System is the all-weather mentors. They are experienced and ready to help any struggling trader.`,
		url: "https://www.trustpilot.com/reviews/6538d2c42beab47c69d2042a"
	},
	{
		author: "Randy In CT",
		review: `Great customer service, when I called for help getting started telephone support was very good. Then I EMailed support for help with broker integration and support was quick and helpful and my issue was quickly resolved. The team at StocksToTrade has put a massive amount of work into the platform and guidance videos. All of the info is extremely useful and right on point. And the trading platform is high quality, high end, and super hi tech.`,
		url: "https://www.trustpilot.com/reviews/659af39123ce1266a66accf1"
	},
	{
		author: "Lawrence Greenberg",
		review: `You are giving us the best possible information..the site has much ease of use..just click on a symbol and it opens a chart..all the stock details, volume, float and such is all on the same screen..trading is not easy, even with all the proper information, but you give as much info as possible, and the webinars in the morning and afternoon are priceless for extra guidance..and and then it will always be up to the trader, timing of entry, how much traded, time of exit, using stop loss along with entry point is a very serious skill..It's easy to bond with the lead traders from StocksToTrade also!`,
		url: "https://www.trustpilot.com/reviews/659827863983d6dec6f8d6da"
	},
	{
		author: "C. M Borders",
		review: `Great company. They have so many tools to assist you with day trading stocks. The people are very knowledgeable. Their customer support is awesome as well.`,
		url: "https://www.trustpilot.com/reviews/659487482a26c7a69d56a429"
	}
];
const breakpoints = reactive(
	useBreakpoints({
		small: 768
	})
);

const glideInit = async () => {
	let options = {
		rewind: false,
		bound: true,
		startAt: 0,
		focusAt: 0
	};

	if (breakpoints.isGreater("small")) {
		options = {
			...options,
			perView: 3,
			peek: {
				before: 200,
				after: 0
			}
		};
	} else {
		options = {
			...options,
			perView: 1,
			peek: {
				before: 0,
				after: 0
			},
			gap: 24
		};
	}

	const glide = new Glide("#bohen-slider", options);

	glide.mount();
};
onMounted(async () => {
	if (process.client) {
		await glideInit();
	}
});
</script>

<style scoped lang="scss">
#glide-slider {
	overflow-x: hidden;
}
.glide {
	position: relative;
	z-index: 9;
}

.glide__slides {
	overflow: initial;
}
.glide__slide {
	max-width: 400px;
	height: 100%;
	margin-left: 0 !important;
	margin-right: 0 !important;
	// width: calc(100vw - 56px);
	width: 100%;
	@include breakpoint(lg) {
		min-height: 512px;
		width: auto;
	}

	// &:last-child {
	// 	padding-right: 2px;
	// }
}

.slider-card {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 24px;
	position: relative;
	z-index: 2;
	@include breakpoint(lg) {
		height: 100%;
		min-height: 496px;
	}
	&::before {
		@include breakpoint(lg) {
			content: "";
			position: absolute;
			height: 100%;
			width: 106%;
			left: -16px;
			filter: blur(8px);
			background: linear-gradient(
				178deg,
				rgba(9, 13, 24, 0) 5%,
				rgba($black, 1) 82%
			);
			z-index: 0;
		}
	}
}

.text {
	font-size: 20px;
	color: $white;
	line-height: 1.3;
	letter-spacing: 0.5px;

	@include breakpoint(lg) {
		font-size: 28px;
	}
}

.slider-button {
	height: 48px;
	width: 48px;
	border: 1px solid white;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 32px;
}

.slider-card-image {
	position: absolute;
	z-index: -1;
	bottom: 64px;
}

.glide__track {
	// padding: 0 24px;
}

.glide__slides {
	gap: 24px;
}

.slider-container {
	top: 0;
	left: 0;
	right: 0;
	margin: auto;

	@include breakpoint(lg) {
		overflow: hidden;
		width: calc(100% + 19.25vw + 176px);
	}
}

.glide__track {
	overflow: initial;
	@include breakpoint(lg) {
		overflow: hidden;
	}
}
#slider {
	position: relative;
	@include breakpoint(lg) {
		left: -172px;
	}
	&::before {
		@include breakpoint(lg) {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			height: 100%;
			width: 200px;
			background: linear-gradient(
				90deg,
				rgba(9, 13, 24, 1) 20%,
				rgba(0, 0, 0, 0) 95%
			);
		}
	}
}

.testimonail-abr {
	height: 36px;
	width: 36px;
	display: flex;
	align-items: center;
	background-color: #4383ff;
	text-align: center;
	justify-content: center;
	border-radius: 32px;
	text-transform: uppercase;
}
</style>
