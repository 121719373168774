<template>
	<HeroSection />

	<ScannersPitchGridSection />

	<FeaturesSection />

	<TestimonialSlider />

	<TrainersSection />

	<Slider />

	<ByTheNumbers />

	<ClientOnly>
		<Row>
			<Container>
				<PopularPostsSection />
			</Container>
		</Row>
	</ClientOnly>
	<SttOnYoutubeDesktop />
	<Head v-if="page && page.yoast_meta">
		<Title>StocksToTrade | The All-In-One Trading Platform</Title>

		<Link
			:href="current_hostname.slice(0, -1)"
			rel="canonical"
		/>
		<Meta
			:content="current_hostname.slice(0, -1)"
			property="og:url"
		/>

		<template
			v-for="(tag, i) in page.yoast_meta"
			:key="i"
		>
			<Meta
				:property="tag.property"
				:content="tag.content"
				v-if="tag.property && tag.property !== 'og:url'"
			/>
			<Meta
				:name="tag.name"
				:content="tag.content"
				v-else-if="
					tag.name &&
					![
						'canonical',
						'WordPressAuthor:YoastHeadJson_Schema_',
						'schema:json',
						'title'
					].includes(tag.name)
				"
			/>
		</template>
	</Head>
</template>

<script setup>
import anime from "animejs";
import axios from "axios";

const { current_hostname, hostname } = useRuntimeConfig().public;
const router = useRoute();

const breakpoints = reactive(
	useBreakpoints({
		small: 768
	})
);

const { data } = await useAsyncData("home", async ({ $config }) => {
	try {
		const { data } = await axios.get(
			$config.public["hostname"] + "wp-json/wp/v2/pages/4"
		);
		if (data) {
			return { page: data };
		}
	} catch (e) {
		console.log(e);
	}
});

const { page } = data.value;

// Generate a random number between -10 and +15
const randomNumber = (position = 0) => {
	const value = Math.floor(Math.random() * (15 + -(-15) + 1)) + -15;

	let pos = 0;
	if (value > 0) {
		pos = position + value;
	} else {
		pos = position - value;
	}

	return pos;
};

const animationObserver = async () => {
	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry, i) => {
			if (entry.isIntersecting) {
				const element = entry.target;
				const animateFlag = element.getAttribute("data-animate");
				if (animateFlag === "true") {
					if (entry.target.id === "youtube-thumbnails") {
						anime({
							targets: "#youtube-thumbnails .youtube-item",
							opacity: [0, 1],

							delay: anime.stagger(250),
							easing: "easeInOutQuad"
						});
					}

					if (entry.target.id === "stay-ahead-main-image") {
						anime({
							targets: "#stay-ahead-main-image",
							opacity: [0, 1],
							easing: "easeInOutQuad",
							translateY: [350, 0],
							duration: 600,
							delay: 50
						});
					}
					// if (entry.target.id === "scanner-pitch-1-img-track") {
					// 	console.log("#scanner-pitch-1-img");
					// 	let cssProperties = {};

					// 	if (breakpoints.isGreater("small")) {
					// 		cssProperties.translateY = [300, 0];
					// 	} else {
					// 		cssProperties.translateY = [400, 24];
					// 		cssProperties.scale = [1.25, 1.25];
					// 	}
					// 	anime({
					// 		targets: "#scanner-pitch-1-img",
					// 		opacity: [0.35, 1],
					// 		easing: "easeInOutQuad",
					// 		...cssProperties,
					// 		duration: 500,
					// 		delay: 150
					// 	});
					// }

					// if (entry.target.id === "scanner-pitch-2-img") {
					// 	let cssProperties = {};

					// 	if (breakpoints.isGreater("small")) {
					// 		cssProperties.translateX = [600, 0];
					// 	} else {
					// 		cssProperties.translateY = [400, 0];
					// 	}

					// 	anime({
					// 		targets: "#scanner-pitch-2-img",
					// 		opacity: [0.35, 1],
					// 		easing: "easeInOutQuad",
					// 		...cssProperties,
					// 		duration: 600,
					// 		delay: 250
					// 	});
					// }

					// if (entry.target.id === "scanner-pitch-3-img") {
					// 	let cssProperties = {};

					// 	if (breakpoints.isGreater("small")) {
					// 		cssProperties.translateX = [600, 0];
					// 	} else {
					// 		cssProperties.translateY = [400, 0];
					// 	}

					// 	anime({
					// 		targets: "#scanner-pitch-3-img",
					// 		opacity: [0.35, 1],
					// 		easing: "easeInOutQuad",
					// 		...cssProperties,
					// 		duration: 600,
					// 		delay: 150
					// 	});
					// }

					// if (entry.target.id === "feature-img-1") {
					// 	anime({
					// 		targets: "#feature-img-1",
					// 		opacity: [0, 1],
					// 		easing: "easeInOutQuad",
					// 		translateX: [150, 0],
					// 		duration: 600,
					// 		delay: 200
					// 	});
					// }

					// if (entry.target.id === "feature-img-2") {
					// 	anime({
					// 		targets: "#feature-img-2",
					// 		opacity: [0, 1],
					// 		easing: "easeInOutQuad",
					// 		translateX: [-150, 0],
					// 		duration: 600,
					// 		delay: 150
					// 	});
					// }

					// if (entry.target.id === "feature-img-3") {
					// 	anime({
					// 		targets: "#feature-img-3",
					// 		opacity: [0, 1],
					// 		easing: "easeInOutQuad",
					// 		translateX: [150, 0],
					// 		duration: 600,
					// 		delay: 150
					// 	});
					// }

					if (entry.target.classList.contains("data-circle")) {
						let initialPos = entry.target.getAttribute("data-position");

						initialPos = initialPos ? Number(initialPos) : 0;

						console.log({ initialPos });

						const dataCircleIndex = entry.target.getAttribute("data-stat");

						anime({
							targets: `#${entry.target.id}`,
							opacity: [0, 1],
							easing: "easeInOutQuad",
							duration: 250,
							delay: dataCircleIndex ? dataCircleIndex * 50 : 0
						});

						anime({
							targets: `#${entry.target.id}`,
							easing: "cubicBezier(.5, .05, .1, .3)",
							direction: "alternate",
							translateY: [
								{ value: randomNumber(initialPos), duration: 1000 },
								{ value: randomNumber(initialPos), duration: 1000 },
								{ value: randomNumber(initialPos), duration: 1000 },
								{ value: randomNumber(initialPos), duration: 1000 }
							],
							translateX: [
								{ value: randomNumber(), duration: 1000 },
								{ value: randomNumber(), duration: 1000 },
								{ value: randomNumber(), duration: 1000 },
								{ value: randomNumber(), duration: 1000 }
							],
							loop: true,
							delay: dataCircleIndex ? dataCircleIndex * 250 : 0
						});
					}

					element.setAttribute("data-animate", "false");

					observer.unobserve(element);
				}
			}
		});
	});

	const animatedElements = await document.querySelectorAll("[data-animate]");
	await animatedElements.forEach((element) => {
		observer.observe(element);
	});
};
onMounted(async () => {
	// Create the script element
	const scriptElement = document.createElement("script");
	// Set the source URL of the script
	scriptElement.innerHTML = page.yoast_meta[14].content;
	scriptElement.classList.add(`saswp-schema-markup-output`);
	scriptElement.type = "application/ld+json";
	document.head.appendChild(scriptElement);

	const wistia_script = document.createElement("script");
	wistia_script.src = "https://fast.wistia.com/assets/external/E-v1.js";
	wistia_script.async = true;

	if (process.client) {
		// Intersection Observer setup
		await animationObserver();
	}
});
</script>

<style lang="scss" scoped>
h1 {
	@include breakpoint(lg) {
		font-size: 90px;
	}
}

[data-animate="true"] {
	opacity: 0;
}
</style>
