<template>
	<Row class="blue-gradient">
		<Container class="by-the-numbers-section">
			<div class="flex xl:pr-[30%] lg:mb-[80px]">
				<Heading
					text="By the Numbers: See how StocksToTrade has empowered traders worldwide."
					tag="h2"
					type="primary"
					class="text-center lg:text-left"
				/>
			</div>
			<div
				class="flex pt-[36px] md:pt-[80px] gap-[24px] md:gap-0 justify-center lg:justify-between flex-wrap"
			>
				<span
					:id="`data-circle-${key + 1}`"
					class="data-circle"
					data-animate="true"
					ref="dataCircle"
					:data-position="transformSwitch(key)"
					v-for="(item, key) in data"
					:key="key"
					:data-stat="key + 1"
				>
					<component :is="item.component" />
					<h3 class="text-[24px] lg:text-[40px]">{{ item.stat }}</h3>
					<p class="text-[14px] lg:text-xl">
						{{ item.label }}
					</p>
				</span>
			</div>
		</Container>
	</Row>
</template>

<script setup>
import {
	StockMarket,
	SocialResponsibility,
	FinancialProfit,
	Medal,
	Users
} from "~/components/svg-icons";

const dataCircle = ref();
const breakpoints = reactive(
	useBreakpoints({
		large: 1280,
		small: 768
	})
);

const transformSwitch = (index) => {
	let positionYValue = 0;
	switch (index) {
		case 1:
			if (dataCircle.value && breakpoints.isGreater("small")) {
				dataCircle.value[index].style.transform = `translateY(${-140}px)`;
				positionYValue = -140;
			}
			break;
		case 3:
			if (dataCircle.value && breakpoints.isGreater("small")) {
				dataCircle.value[index].style.transform = `translateY(${-96}px)`;
				positionYValue = -96;
			}
			break;
	}

	return positionYValue;
};

const data = [
	{
		component: StockMarket,
		stat: "1.8 Million",
		label: "Trades Made"
	},
	{
		component: SocialResponsibility,
		stat: "115,000",
		label: "Traders Helped"
	},

	{
		component: Medal,
		stat: "2,642",
		label: "Winning Oracle Picks"
	},
	{
		component: Users,
		stat: "21,437",
		label: "Active Members"
	}
];
</script>

<style lang="scss" scoped>
.blue-gradient {
	background: linear-gradient(
		180deg,
		rgba(9, 13, 24, 0) 16%,
		rgba(27, 46, 211, 0.33) 59.79%,
		rgba(34, 41, 191, 0.42) 75.37%,
		rgba(9, 13, 24, 0.09) 100%,
		rgba(9, 13, 24, 0.09) 100%
	);
}
.by-the-numbers-section {
	max-width: 1344px;
	background: linear-gradient(
		73deg,
		rgba(0, 87, 255, 0.2) 4.01%,
		rgba(222, 89, 255, 0.2) 81.74%
	);

	padding: 56px 24px;

	@include breakpoint(lg) {
		border-radius: 32px;
		padding: 56px 48px;
	}
}

.data-circle {
	opacity: 0;
	min-width: 148px;
	max-width: 148px;
	max-height: 148px;
	min-height: 148px;
	width: 100%;
	height: 100%;
	display: inline-flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 300px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	background: rgba(255, 255, 255, 0.08);
	position: relative;
	padding: 0 16px;

	svg {
		height: 40px;
		width: 40px;

		@include breakpoint(lg) {
			height: auto;
			width: auto;
		}
	}

	@include breakpoint(lg) {
		min-width: 300px;
		max-width: 300px;
		max-height: 300px;
		min-height: 300px;
		&.lone {
			transform: translateX(-80px);
		}
		&[data-stat="2"] {
			// transform: translateY(-140px) !important;
		}
		&[data-stat="4"] {
			// transform: translateY(-96px) !important;
		}
	}
}
</style>
