<template>
	<NuxtLink
		:href="link"
		class="trainer-btn flex items-center gap-3 mt-4 self-start"
	>
		<span :class="textClass">
			{{ label }}
		</span>
		<div class="p-2 bg-blue-300 rounded-full flex items-center justify-center">
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="Right">
					<path
						id="Vector"
						d="M14 4.92969L12.5 6.42969L17.0703 11H3V13H17.0703L12.5 17.5703L14 19.0703L21.0703 12L14 4.92969Z"
						fill="white"
					/>
				</g>
			</svg>
		</div>
	</NuxtLink>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps(["label", "link", "textColor"]);

const textClass = computed(() => {
	return props.textColor ? props.textColor : "text-blue-300";
});
</script>

<style lang="scss" scoped>
.trainer-btn:hover {
	span {
		color: $primary-700;
	}

	div {
		background-color: $primary-700;
	}
}
</style>
